import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import { MoralisProvider } from "react-moralis";
import "./index.css";

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

const Application = () => {
  return <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
    <App isServerInfo />
  </MoralisProvider>
};
createRoot(document.getElementById("root")).render(<Application />)
