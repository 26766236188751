import { Layout } from 'antd';
import { Footer, Header } from 'antd/lib/layout/layout';
import Text from "antd/lib/typography/Text";
import Account from 'components/Account';
import MenuItems from 'components/MenuItems';
import NativeBalance from 'components/NativeBalance';
import TokenPrice from 'components/TokenPrice';
import React from 'react'

const styles = {
  content: {
    // display: "flex",
    // justifyContent: "center",
    // fontFamily: "Roboto, sans-serif",
    // color: "#041836",
    marginTop: "130px",
    // padding: "10px",
  },
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    background: "rgba(0, 0, 0, 0.25)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "0 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
    height: "90px",
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "600",
  },
  footer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center", 
  }
};

export default function Main({Component}) {
  const todays_date = new Date();

  return (
    <Layout style={{ height: "100vh", overflow: "auto" }}>    
    <Header className="backdrop-blur-md" style={styles.header}>
      <div className="d-flex container text-white">
        <a href="/">
        <Logo />
        </a>
        {/* <SearchCollections setInputValue={setInputValue}/> */}
        <MenuItems />
        <div style={styles.headerRight}>
          {/* <Chains /> */}
          <TokenPrice
            address="0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
            chain="eth"
            image="https://scmoneyclub.com/public/assets/img/ethereum-logo.svg"
            size="40px"
          />
          <NativeBalance />
          <Account />
        </div>
      </div>
    </Header>

    <div style={styles.content}>
      <Component />
    </div>
  <Footer className="my-5" style={styles.footer}>
    <a href="https://scmoneyclub.com">
      <Logo className="mb-5"/>
    </a>
    <Text style={{ display: "block", marginTop: "20px", color: "#fff" }}>
      "Vires In Numeris"<br/>
      <a
        href="https://scmoneyclub.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#fff" }}
      >
        SC Money Club
      </a> &copy; {todays_date.getFullYear()}
    </Text>        
  </Footer>
</Layout>
  )
}
export const Logo = () => (
  <div style={{ display: "flex", height: "56px", minWidth: "56px" }}>
    <img src="https://scmoneyclub.com/public/assets/logo/scmoneyclub-logo-white.svg"
      height="64"
      width="64" />
  </div>
);
