import { useEffect, useState} from "react";
import { useMoralis} from "react-moralis";
import { BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import MembersPage from "components/MembersPage";
import AccountPage from "components/AccountPage";
import ERC20Balance from "components/ERC20Balance";
import ERC20Transfers from "components/ERC20Transfers";
import NFTTokenIds from "components/NFTTokenIds";
import NFTBalance from "components/NFTBalance";
import Wallet from "components/Wallet";
import "antd/dist/antd.css";
import "./style.css";
import Minter from "components/Minter";
import Main from "layouts/Main";

const App = ({ isServerInfo}) => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading} = useMoralis();

  // const [inputValue, setInputValue] = useState('0x62C2294e6E5c3c6Ec73195748E4fEd15a3BbA3F1');
  const [inputValue, setInputValue] = useState('explore');

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [isAuthenticated, isWeb3Enabled]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main Component={Minter} />} />
        {/* <Route path="/nftMarket" element={<Main Component={NFTTokenIds inputValue={inputValue} setInputValue={setInputValue}}></Main>} /> */}
        <Route path="/members" element={<Main Component={MembersPage}></Main>} />
        <Route path="/nftBalance" element={<Main Component={NFTBalance}></Main>} />
        <Route path="/transactions" element={<Main Component={NFTBalance}></Main>} />
        <Route path="/account" element={<Main Component={AccountPage}></Main>} />
        <Route path="/wallet" element={<Main Component={Wallet}></Main>} />
      </Routes>
    </Router>
  );
};

export default App;
