import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, InputNumber, Checkbox, Typography, Alert, Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import { useMoralis, useMoralisQuery, useWeb3ExecuteFunction } from 'react-moralis';
import minterContractAbi from './../abis/minterContract.json';
import Web3 from 'web3';

export default function Minter() {
 const { error, fetch, isFetching, isLoading } = useWeb3ExecuteFunction();
 const { authenticate, isAuthenticated, account } = useMoralis();
 const [totalEth, setTotalEth] = useState(0);
 const [disableMinting, setDisableMinting] = useState(false);
 const [mintButtonLabel, setMintButtonLabel] = useState("Mint");

 const defaultTokenOptions = [
  {
   label: 'Junior Investor',
   id: 3,
   price: 50000000000000000,
   selectedAmount: 0,
   selected: false,
   maxAllowed: 50,
  },
  {
   label: 'Investor',
   id: 2,
   price: 100000000000000000,
   selectedAmount: 0,
   selected: false,
   maxAllowed: 25,
  },
  {
   label: 'Senior Investor',
   id: 1,
   price: 200000000000000000,
   selectedAmount: 0,
   selected: false,
   maxAllowed: 10,
  },
 ];

 const [tokenOptions, setTokenOptions] = useState(defaultTokenOptions);

 const updateTotalEth = () => {
  const te = [...tokenOptions];
  let total = 0;
  for (let i = 0; i < te.length; i++) {
   const to = te[i];
   if (to.selected === true && to.selectedAmount > 0) {
    total += to.price * to.selectedAmount;
   }
  }
  setTotalEth(total);
 };

 useEffect(() => {
  updateTotalEth();
 }, [tokenOptions]);

 const onMintClick = (e) => {
  if (disableMinting) {
   return;
  }
  const ids = tokenOptions
   .map((e) => {
    return e.selected ? e.id : '';
   })
   .filter(function (i) {
    return i !== '';
   });

  const amounts = tokenOptions
   .map((e) => {
    return e.selected ? e.selectedAmount : '';
   })
   .filter(function (i) {
    return i !== '';
   });

  if (ids.length === 0 || amounts.length === 0 || ids.length !== amounts.length) {
   alert('Please select tokens with amounts');
   return;
  }

  const ops = {
   contractAddress: process.env.REACT_APP_MINTER_CONTRACT_ADDRESS,
   functionName: 'mintMultiple',
   abi: minterContractAbi,
   msgValue: totalEth,
   params: {
    ids: ids,
    amounts: amounts,
   },
  };
  setDisableMinting(true);
  setMintButtonLabel("Pending...")
  fetch({
   params: ops,
   onSuccess: (tx) =>{
    setMintButtonLabel("Minting...")
    tx.wait().then(() => {
     console.log(tx);
     setTokenOptions(defaultTokenOptions);
     setDisableMinting(false);
     setMintButtonLabel("Mint")
    })
    },
    onError: ()=>{
      setDisableMinting(false);
      setMintButtonLabel("Mint")
    }
  });
 };

 const onChangeType = (checkedValues) => {
  const _tokenOptions = [...tokenOptions];
  for (let j = 0; j < _tokenOptions.length; j++) {
    if (checkedValues.id === _tokenOptions[j].id) {
      console.log(checkedValues)
      console.log(_tokenOptions[j].selected)
      _tokenOptions[j].selected = (checkedValues.selected?false:true);
      _tokenOptions[j].selectedAmount = (_tokenOptions[j].selected?1:0)
    }
  }
  setTokenOptions([..._tokenOptions]);
 };

 const onAmountChange = (selectedAmount, to) => {
  if (selectedAmount >= to.maxAllowed) {
   selectedAmount = to.maxAllowed;
  }
  if (selectedAmount <= 0) {
   selectedAmount = 0;
  }
  const _tokenOptions = [...tokenOptions];
  _tokenOptions.map((_to, k) => {
   if (_to.id === to.id) {
    _tokenOptions[k].selectedAmount = selectedAmount;
    if (selectedAmount <= 0) {
      _tokenOptions[k].selected = false
    }
   }
  });
  setTokenOptions(_tokenOptions);
 };

 return (
  <div>
    <Alert
      message="Warning"
      description="This app is in test mode and only works in Rinkeby Network right now"
      type="warning"
      showIcon
      style={{ width: '100%', maxWidth: '800px', margin: '0 auto 20px auto' }}

    />
   <Card
    hoverable
    style={{ width: '100%', maxWidth: '400px', margin: '0 auto', display: 'block', padding: '10px' }}
    cover={
     <>
      <Typography.Title
       level={1}
       style={{ margin: '5px 0', textAlign: 'center', color: '#ffffff', fontWeight: 'normal' }}
      >
       Get Your Rank
      </Typography.Title>
      <img
       alt='SC Money Club'
       src='https://scmoneyclub.com/public/assets/logo/scmoneyclub-logo-white.svg'
       style={{ width: '50%', margin: '0 auto', display: 'block' }}
      />
     </>
    }
   >
    <Form
     layout='horizontal'
     size='large'
    >
    {tokenOptions.map((to) => {
      return (
        <Form.Item
        key={to.id}
        style={{
          margin: '0',
        }}
        >
        <Checkbox
          checked={to.selected}
          value={to.id}
          style={{ color: '#ffffff', fontSize: '18px', width: '100%', margin: '0' }}
          onClick={()=>{onChangeType(to)}}
        >
          {to.label}
        </Checkbox>

        {to.selected && (
          <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            margin: '10px auto',
          }}
          >
          <Button
            shape='circle'
            icon={<MinusOutlined />}
            size='medium'
            onClick={(e) => {
            return onAmountChange(to.selectedAmount - 1, to);
            }}
          />
          <h4
            style={{
            color: '#ffffff',
            }}
          >
            {to.selectedAmount} / {to.maxAllowed}
          </h4>
          <Button
            shape='circle'
            icon={<PlusOutlined />}
            size='medium'
            onClick={(e) => {
            return onAmountChange(to.selectedAmount + 1, to);
            }}
          />
          </div>
        )}
        </Form.Item>
      );
    })}

     {isAuthenticated && account !== null && totalEth !== 0 && (
      <Form.Item>
       <Button
        ghost='true'
        style={{ margin: '0 auto', display: 'block' }}
        onClick={onMintClick}
        loading={isFetching || isLoading}
        disabled={isFetching || isLoading || disableMinting}
       >
        { mintButtonLabel } ({Web3.utils.fromWei(totalEth.toString(), 'ether')} ETH)
       </Button>
       {error && (
        <Alert
         message={error.message}
         type='error'
        />
       )}
      </Form.Item>
     )}
     {(!isAuthenticated || account === null) && (
      <div
       className='btn btn-primary btn-has-inner-text mx-auto d-block'
       onClick={() => authenticate({ signingMessage: 'Welcome to SCMoney Club' })}
       style={{ maxWidth: '150px' }}
      >
       <div className='multi-colored-shadow-hover-only'></div>
       <div className='btn-inner-text text-glow-blue hover:text-white transition-all text-center w-100 d-block'>Connect Wallet</div>
      </div>
     )}
    </Form>
   </Card>
  </div>
 );
}
