import { useMoralis } from "react-moralis";
import { getEllipsisTxt } from "helpers/formatters";
// import Blockie from "./Blockie";
import { Button, Card, Modal } from "antd";
import { useState } from "react";
import Address from "./Address/Address";
import { SelectOutlined } from "@ant-design/icons";
import { getExplorer } from "helpers/networks";

const styles = {
  account: {
    minWidth: "144px",
  },
};

function Account() {
  const { authenticate, isAuthenticated, logout, account, chainId } = useMoralis();
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (!isAuthenticated || account === null) {
    return (
      <div className="btn btn-primary btn-has-inner-text" style={styles.account} onClick={() => authenticate({ signingMessage: "Welcome to SCMoney Club" })}>
        <div className="multi-colored-shadow-hover-only"></div>
        <div className="btn-inner-text text-glow-blue hover:text-white transition-all">
          Connect Wallet
        </div>
      </div>
    );
  }

  return (
    <>
      <button type="button" className="header-account-btn btn btn-primary btn-has-inner-text" style={styles.account} onClick={() => setIsModalVisible(true)}>
        <div className="multi-colored-shadow-hover-only"></div>
        <div className="btn-inner-text text-glow-blue hover:text-white transition-all">
          {getEllipsisTxt(account, 6)}
        </div>
      </button>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        bodyStyle={{
          padding: "15px",
          fontSize: "17px",
          fontWeight: "500",
        }}
        style={{ fontSize: "16px", fontWeight: "500" }}
        width="400px"
      >
        Account
        <Card
          style={{
            marginTop: "10px",
            borderRadius: "1rem",
          }}
          bodyStyle={{ padding: "15px" }}
        >
          <Address avatar="left" size={6} copyable style={{ fontSize: "20px", color: "black" }} />
          <div style={{ marginTop: "10px", padding: "0 10px" }}>
            <a href={`${getExplorer(chainId)}address/${account}`} target="_blank" rel="noreferrer">
              <SelectOutlined style={{ marginRight: "5px" }} />
              View on Explorer
            </a>
          </div>
        </Card>
        <Button
          size="large"
          type="primary"
          style={{
            width: "100%",
            marginTop: "10px",
            borderRadius: "0.5rem",
            fontSize: "16px",
            fontWeight: "500",
          }}
          onClick={() => {
            logout();
            setIsModalVisible(false);
          }}
        >
          Disconnect Wallet
        </Button>
      </Modal>
    </>
  );
}

export default Account;
