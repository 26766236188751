import { useMoralis } from "react-moralis";
import { getEllipsisTxt } from "helpers/formatters";
import Blockie from "./Blockie";
import { Button, Card, Tabs, Modal } from "antd";
import { useState } from "react";
import Address from "./Address/Address";
import { SelectOutlined } from "@ant-design/icons";
import { getExplorer } from "helpers/networks";
import Wallet from "components/Wallet";
import ERC20Balance from "components/ERC20Balance";
import ERC20Transfers from "components/ERC20Transfers";
const styles = {
  account: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    cursor: "pointer",
  },
  text: {
    color: "#21BF96",
  },
};

function AccountPage() {
  const { authenticate, isAuthenticated, logout, account, chainId } = useMoralis();
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (!isAuthenticated) {
    return (
      <div style={styles.account} onClick={() => authenticate({ signingMessage: "Hello World!" })}>
        <p style={styles.text}>Authenticate</p>
      </div>
    );
  }

  const { TabPane } = Tabs;

  function callback(key) {
    console.log(key);
  }

  const AccountTabs = () => (
    <Tabs id="account-tabs" defaultActiveKey="1" onChange={callback} className="text-white" style={{ border: "none" }}>
      <TabPane tab="Transfer" key="1">
        <Wallet />
      </TabPane>
      <TabPane tab="Balances" key="3">
        <ERC20Balance />
      </TabPane>
      <TabPane tab="Transactions" key="2">
        <ERC20Transfers />
      </TabPane>
    </Tabs>
  );

  return (
    <>
      <div id="account-page" className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-auto">
            <button type="button" className="header-account-btn btn btn-primary btn-has-inner-text" style={styles.account} onClick={() => setIsModalVisible(true)}>
              <div className="multi-colored-shadow-hover-only"></div>
              <div className="btn-inner-text">
                <div>{getEllipsisTxt(account, 6)}</div>
                {/* <Blockie currentWallet scale={3} /> */}
              </div>
            </button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-auto">
            <AccountTabs />
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        bodyStyle={{
          padding: "15px",
          fontSize: "17px",
          fontWeight: "500",
        }}
        style={{ fontSize: "16px", fontWeight: "500" }}
        width="400px"
      >
        Account
        <Card
          style={{
            marginTop: "10px",
            borderRadius: "1rem",
          }}
          bodyStyle={{ padding: "15px" }}
        >
          <Address avatar="left" size={6} copyable style={{ fontSize: "20px", color: "black" }} />
          <div style={{ marginTop: "10px", padding: "0 10px" }}>
            <a href={`${getExplorer(chainId)}address/${account}`} target="_blank" rel="noreferrer">
              <SelectOutlined style={{ marginRight: "5px" }} />
              View on Explorer
            </a>
          </div>
        </Card>
        <Button
          size="large"
          type="primary"
          style={{
            width: "100%",
            marginTop: "10px",
            borderRadius: "0.5rem",
            fontSize: "16px",
            fontWeight: "500",
          }}
          onClick={() => {
            logout();
            setIsModalVisible(false);
          }}
        >
          Disconnect Wallet
        </Button>
      </Modal>
    </>
  );
}

export default AccountPage;
