
export const getMembers = (
    rank = '',
    posts_per_page = 6,
    offset = 0
  ) => {
  let fetch_url = 'https://api.scmoneyclub.com/wp-json/scmoneyclub/v1/members?'
  if (rank !== '') {
    fetch_url += '&rank=' + rank
  }
  fetch_url += '&posts_per_page=' + posts_per_page
  fetch_url += '&offset=' + offset
  return fetch(fetch_url).then(response => response.json())
}
